import React from "react"
import Spinner from "components/Spinner"
import loadable from "@loadable/component"

const LoadableContact = loadable(() => import("components/ContactPage"), {
  fallback: <Spinner />,
})

const IndexPage = () => {
  return <LoadableContact />
}

export default IndexPage
